import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { OnlynumberDirective } from '@core/directives/Onlynumbers.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective,OnlynumberDirective],
  exports: [RippleEffectDirective, FeatherIconDirective,OnlynumberDirective]
})
export class CoreDirectivesModule {}
