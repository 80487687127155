import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Anasayfa',
    translate: 'Anasayfa',
    type: 'item',
    icon: 'home',
    url: 'dashboard'
  },
  {
    id: 'users',
    title: 'Kullanıcılar',
    translate: 'Kullanıcılar',
    type: 'item',
    icon: 'user',
    url: 'users'
  },
  {
    id: 'usersPendingApprove',
    title: 'Onay Bekleyen Üyeler',
    translate: 'Onay Bekleyen Üyeler',
    type: 'item',
    icon: 'circle',
    url: 'users-pending-approval'
  },
  {
    id: 'products',
    title: 'Ürünler',
    translate: 'Ürünler',
    type: 'item',
    icon: 'shopping-cart',
    url: 'products'
  },
  {
    id: 'productsPendingApproval',
    title: 'Onay Bekleyen Ürünler',
    translate: 'Onay Bekleyen Ürünler',
    type: 'item',
    icon: 'circle',
    url: 'products-pending-approval'
  },
  {
    id: 'leases',
    title: 'Kiralamalar',
    translate: 'Kiralamalar',
    type: 'item',
    icon: 'key',
    url: 'leases'
  },
  {
    id: 'provision',
    title: 'Provizyonlar',
    translate: 'Provizyonlar',
    type: 'item',
    icon: 'credit-card',
    url: 'provisions'
  },
  {
    id: 'contactForm',
    title: 'İletişim Formları',
    translate: 'İletişim Formları',
    type: 'item',
    icon: 'file-text',
    url: 'contact-forms'
  },
  {
    id: 'reports',
    title: 'Raporlar',
    translate: 'Raporlar',
    type: 'collapsible',
    icon: 'file-text',
    children: [
      {
        id: 'memberReport',
        title: 'Kullanıcı Raporu',
        translate: 'Kullanıcı Raporu',
        type: 'item',
        icon: 'file-text',
        url: 'reports/member'
      },
      {
        id: 'productReport',
        title: 'Ürün Raporu',
        translate: 'Ürün Raporu',
        type: 'item',
        icon: 'file-text',
        url: 'reports/product'
      },
      {
        id: 'reservationReport',
        title: 'Rezervasyon Raporu',
        translate: 'Rezervasyon Raporu',
        type: 'item',
        icon: 'file-text',
        url: 'reports/reservation'
      }
    ]
  },
  {
    id: 'campaign',
    title: 'Promosyon Kodları',
    type: 'item',
    icon: 'file-text',
    url: 'campaign'
  },
  {
    id: 'ticket',
    title: 'Ticket',
    type: 'item',
    icon: 'file-text',
    url: 'ticket'
  }
]
