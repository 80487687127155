import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  token: string
  constToken = "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiMTcyIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvZ2l2ZW5uYW1lIjoidW5rbm93biIsImV4cCI6MTY2MzI0MDk3NCwiaXNzIjoiaHR0cHM6Ly9vcnRhay5hcHAiLCJhdWQiOiJodHRwczovL29ydGFrLmFwcCJ9.VwSJbqzsYU1VDdMFK5z4jgVZ7Tpk9O2hh4EB0T-6NaE"
  constructor(
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = localStorage.getItem('token')
    if (this.token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.token) });
    }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    this.handleError(err.status);
    return throwError(err);
  }

  private handleError(errCode: number) {
    switch (errCode) {
      case 401 || 402 || 403:
        this.router.navigate(['/authentication/login']);
        break;
      case 500:

        break;
      case 400:

        break;
      case 404:

        break;
      case 405:

        break;
      case 502:

        break;
      default:

        break;
    }
  }
}
